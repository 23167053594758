import axios from "axios";
import { useEffect, useState } from "react";
import { ApplicationJsonConfigHeader, BaseUrl } from "../../Api/axios.config";
import InputFull from "../../Components/Common/InuptFull";
import Menu from "../../Components/Template/Menu";
import Template from "../../Components/Template/Template";
import { validateInput } from "../../Components/Utils/ValidationUtils";
import { getProfile } from "../StudentProfile/ProfileApi";
import {
  ErrorMsgToaster,
  SuccessMsgToaster,
} from "../../Components/Utils/Toaster";
import { StudentProfile } from "../../Utils/types";

const checkoutURL: string = `${BaseUrl}/checkout/checkoutComplete`;
const paymentFailureUrl: string = `${BaseUrl}/paymentFailure`;
const paymentSuccessUrl: string = `${BaseUrl}/paymentSuccess`;
const token: string | null = localStorage.getItem("token");

const Checkout = (props: any) => {
  localStorage.setItem('pageData',props.location.state.page)
  useEffect(() => {
    if (performance.navigation.type === 1) {
      handlePaymentFailure();
    }
  });
  const menu = <Menu selectedItem={``} />;
  return <Template menu={menu} body={<Checkoutbody />} submenu={<SubMenu />} />;
};

function handlePaymentFailure() {
  const cartOrderId = localStorage.getItem("orderId");
  const pageData = localStorage.getItem('pageData')
  axios
    .post(
      paymentFailureUrl,
      { orderId: cartOrderId },
      {
        headers: {
          ...ApplicationJsonConfigHeader,
          authorization: `Bearer ${token}`,
        },
        responseType: "json",
      }
    )
    .then((res) => {
      ErrorMsgToaster(res.data);
      if(pageData == 'enroll') {
        window.location.replace("/courses");
      } else {
        window.location.replace("/mycart");
      }
    })
    .catch((error) => {
      ErrorMsgToaster(error);
    });
}

function loadScript(src: any) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

function handlePaymentMethod(result: any) {
  console.log("result", result);
  const {
    amount,
    order_id,
    currency,
    email,
    firstName,
    // keyId,
    mobileNumber,
    address,
  } = result.data;

  const options = {
    key: "rzp_live_8w90ON9MzUXKp9", // Enter the Key ID generated from the Dashboard
    amount: amount.toString(),
    currency: currency,
    name: "Her SpringBoard.",
    description: "Transaction",
    //image: { logo },
    order_id: order_id,
    handler: async function (response: any) {
      const data = {
        orderCreationId: order_id,
        razorpayPaymentId: response.razorpay_payment_id,
        razorpayOrderId: response.razorpay_order_id,
        razorpaySignature: response.razorpay_signature,
        orderId: localStorage.getItem("orderId"),
      };
      await axios
        .post(paymentSuccessUrl, data, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          SuccessMsgToaster(res.data);
          window.location.replace("/myCourses");
        })
        .catch((err) => {
          ErrorMsgToaster(err);
        });
    },
    modal: {
      ondismiss: function () {
        console.log("Checkout form closed");
        handlePaymentFailure();
      },
    },
    prefill: {
      name: firstName,
      email: email,
      contact: mobileNumber,
    },
    notes: {
      address: address,
    },
    theme: {
      color: "#61dafb",
    },
  };

  const paymentObject = new (window as any).Razorpay(options);
  paymentObject.open();
}

async function displayRazorpay(studentDetail: any) {
  const cartOrderId = localStorage.getItem("orderId");

  studentDetail["orderId"] = cartOrderId;
  studentDetail = {
    firstName: studentDetail.firstName,
    lastName: studentDetail.lastName,
    email: studentDetail.email,
    mobileNumber: studentDetail.mobileNumber,
    address: studentDetail.address,
    city: studentDetail.city,
    pincode: studentDetail.pincode,
    country: studentDetail.country,
    state: studentDetail.state,
    orderId: studentDetail.orderId,
  };
  //console.log('studentfinal',studentDetail);

  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }
  console.log("orderId", cartOrderId);
  await axios
    .post(checkoutURL, studentDetail, {
      headers: {
        ...ApplicationJsonConfigHeader,
        authorization: `Bearer ${token}`,
      },
      responseType: "json",
    })
    .then((response) => {
      console.log("complete", response);
      handlePaymentMethod(response);
    })
    .catch((err) => {
      ErrorMsgToaster(err.response.data);
    });
}

const Checkoutbody = () => {
  const [studentDetail, setStudentDetail] = useState<StudentProfile>();
  useEffect(() => {
    getProfile("Student").then((data) => setStudentDetail(data));
  }, []);
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const updateField = (field: Object) => {
    setStudentDetail &&
      setStudentDetail({
        ...studentDetail,
        ...field,
      });
  };
  console.log("stude", studentDetail);
  return (
    <div className={`flex flex-col text-gray-700`}>
      <div className={`-mt-10 text-lp_blue text-2xl font-bold`}>Checkout</div>

      <div className={`grid grid-cols-2 gap-6 w-full mt-8`}>
        <div className={`mt-2`}>
          <InputFull
            type="text"
            label="First Name"
            value={studentDetail?.firstName}
            onChange={(e) => {
              updateField({ firstName: e.target.value });
            }}
            errCls={firstNameErr ? "border-red-400" : "border-gray-400"}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              const isErr = validateInput("name", e.target.value);
              setFirstNameErr(isErr);
            }}
          />
        </div>
        <div className={`mt-2`}>
          <InputFull
            type="text"
            label="Last Name"
            value={studentDetail?.lastName}
            onChange={(e) => {
              updateField({ lastName: e.target.value });
            }}
            errCls={lastNameErr ? "border-red-400" : "border-gray-400"}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              const isErr = validateInput("name", e.target.value);
              setLastNameErr(isErr);
            }}
          />
        </div>
      </div>

      <div className={`grid grid-cols-2 gap-6 w-full mt-2`}>
        <div className={`mt-2 `}>
          <InputFull
            type="email"
            label="Email"
            value={studentDetail?.email}
            onChange={(e) => {
              updateField({ email: e.target.value });
            }}
            errCls={emailErr ? "border-red-400" : "border-gray-400"}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              const isErr = validateInput("email", e.target.value);
              setEmailErr(isErr);
            }}
          />
        </div>
        <div className={`mt-2`}>
          <InputFull
            type="tel"
            label="Mobile"
            value={studentDetail?.mobileNumber}
            maxLength={10}
            onChange={(e) => {
              let val = e.target.value.replace(/([^0-9])/g, "");
              updateField({ mobileNumber: val });
            }}
          />
        </div>
      </div>

      <button
        className={` bg-lp_blue text-white text-xl h-10 w-32 rounded-md font-Lato font-bold place-self-end mt-8`}
        type="button"
        onClick={() => displayRazorpay(studentDetail)}
      >
        CHECKOUT
      </button>
    </div>
  );
};

const SubMenu = () => {
  return <></>;
};
export default Checkout;
