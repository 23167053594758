import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Assets } from "../../Api/Assets";
import UserInfoAPIService from "../../Api/UserInfo/UserInfoAPI";
import ThemeContext from "../../Context/ThemeContext";
import { UserInfoAction } from "../../redux/action";
import Footer from "../../Screens/Footer/Footer";
type templateProps = {
  menu: JSX.Element;
  body: JSX.Element;
  submenu?: JSX.Element;
  setUserInfo: (data: any) => void;
  userInfo: any;
};
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const Template = ({
  menu,
  body,
  submenu,
  setUserInfo,
  userInfo,
}: templateProps) => {
  const { width } = useWindowDimensions();
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const history = useHistory();

  const userInfoAPIService = new UserInfoAPIService();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log("Bahubali code executing");
      history.replace("/signin");
    }
    if (!userInfo.registerType)
      userInfoAPIService
        .fetchUserInfo()
        .then((resData) => {
          if (resData.data) {
            setUserInfo(resData.data);
          }
        })
        .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (userInfo.setGoal === false) {
    //  history.push("/set-goal");
    }
  }, [userInfo]);
  return (
    <ThemeContext.Consumer>
      {(context) => {
        return (
          <div
            className={`grid grid-cols-12  gap-1 w-full h-screen font-auto   bg-white text-gray-600`}
          >
            {width > 1024 ? (
              <div
                className={`hidden lg:flex flex-col   items-center grid-cols-none overflow-auto lg:col-span-2  h-auto pt-8`}
                style={{ backgroundColor: context.theme!.Menu.background }}
              >
                {menu}
              </div>
            ) : (
              <>
                <div className={`flex-row grid-cols-none `}>
                  <div className={`w-8 h-8 mt-2 `}>
                    <img src={Assets.Menu} onClick={handleClick} />
                  </div>

                  <MenuBar menu={menu} click={click} />
                </div>
              </>
            )}

            <div
              className={
                submenu
                  ? ` col-span-8 lg:col-span-7 lg:ml-2 -ml-8 mt-7 py-8 z-0 px-8  overflow-auto `
                  : `col-span-11 lg:col-span-10 mt-7 py-8 z-0 px-8 overflow-auto `
              }
            >
              <div className="min-h-min_h_body">{body}</div>
              <div>
                <Footer />
              </div>
            </div>

            {submenu ? (
              <div
                className={`col-span-3 justify-end h-full z-0 py-10 px-4 overflow-scroll text-xl text-gray-800`}
                style={{ backgroundColor: context.theme!.Submenu.background }}
              >
                {submenu}
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};

type MenuProp = {
  menu: React.ReactElement;
  click: boolean;
};

const MenuBar = ({ menu, click }: MenuProp) => {
  return (
    <ThemeContext.Consumer>
      {(context) => {
        return (
          <>
            {click ? (
              <div
                className={`flex-shrink-0 flex-grow-0 flex justify-start flex-wrap`}
              >
                <div
                  className={`flex flex-col z-40 items-center  h-full `}
                  style={{ backgroundColor: context.theme!.Menu.background }}
                >
                  {menu}
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        );
      }}
    </ThemeContext.Consumer>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserInfo: (data: any) => {
      dispatch(UserInfoAction(data));
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    userInfo: state && state.userInfoReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Template);
