import { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Assets } from "../../Api/Assets";
import AuthService from "../../Api/Auth/Auth";
import InputRadio from "../../Components/Common/InputRadio";
import InputFull from "../../Components/Common/InuptFull";
import Logo from "../../Components/Common/Logo";
import LoginImageTemplate from "../../Components/Utils/LoginImageTemplate";
import { ErrorMsgToaster } from "../../Components/Utils/Toaster";
import { validateInput } from "../../Components/Utils/ValidationUtils";
import { UserInfoAction } from "../../redux/action";
import Routes from "../../Routes/Routes";
import { FaEye } from "react-icons/fa";
import ThemeContext from "../../Context/ThemeContext";

const marginTop = `mt-8`;
const centerItem = `flex items-center justify-center`;

const Signup = (props: any) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [registerType, setRegisterType] = useState("Student");
  const [gender, setGender] = useState("");

  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [cnfPasswordErr, setCnfPasswordErr] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const history = useHistory();
  const token: string | null = localStorage.getItem("token");
  const loginType: string | null = localStorage.getItem("registerType");
  if (token && loginType) {
    if (loginType === "Student") {
      history.push("/dashboard");
    } else if (loginType === "Instructor") {
      history.push("/dashboard");
    }
  }
  const show_Password = () => {
    setShowPassword(!showPassword);
  };
  const show_Confirm_Password = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const signupHandler = async (fetchTheme?: VoidFunction) => {
    // add condition to check both value and error
    if (!firstName) {
      ErrorMsgToaster({ error: "First Name cannot be empty", statusCode: 400 });
      return;
    }
    if (!lastName) {
      ErrorMsgToaster({ error: "Last Name cannot be empty", statusCode: 400 });
      return;
    }
    if (!gender) {
      ErrorMsgToaster({ error: "Please select a Gender", statusCode: 400 });
      return;
    }
    const response = await new AuthService().register({
      email,
      password,
      confirmPassword,
      firstName,
      lastName,
      registerType,
      gender,
    });
    if (response?.data !== undefined) {
      if (fetchTheme !== undefined) await fetchTheme();
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("registerType", response.data.type);
     // history.replace(Routes.setGoal);
    } else {
      toast.error(response?.errorMsg!.error!, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <div
        className={`grid grid-cols-1 lg:grid-cols-2  ml-3 sm:ml-auto font-lato mt-5`}
      >
        <div className={``}>
          <LoginImageTemplate />
        </div>
        <div className={` flex  overflow-y-scroll `}>
          <div className={`${centerItem} flex-1 overflow-y-scroll `}>
            <div>
              <div className={`${centerItem}`}>
                <Logo />
              </div>
              <div
                className={`grid justify-items-center text-lp_blue font-bold text-4xl mt-5`}
              >
                Welcome to ILP
              </div>
              {/* <div
                className={`grid grid-cols-2 gap-3 text-lp_blue text-2xl mt-10`}
              >
                <div className={`text-right`}>Sign Up with</div>
                <div className={`grid grid-cols-4`}>
                  <img src={Assets.FbLogo} alt={`FB`} />
                  <img src={Assets.GoogleLogo} alt={`Google`} />
                  <img src={Assets.LinkedInLogo} alt={`LinkedIn`} />
                </div>
              </div>
              <div
                className={`grid justify-items-center text-lp_blue font-bold text-2xl mt-6`}
              >
                - OR -
              </div> */}
              <div className={`grid sm:grid-cols-2 gap-5 mt-4 `}>
                <div className={`mt-2`}>
                  <InputFull
                    type="text"
                    label="First Name"
                    onChange={(event) => {
                      setFirstName(event.target.value);
                    }}
                    errCls={firstNameErr ? "border-red-400" : "border-gray-400"}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const isErr = validateInput("name", e.target.value);
                      setFirstNameErr(isErr);
                    }}
                  />
                  <div className={`p-1 text-xs text-red-600`}>
                    {firstNameErr}
                  </div>
                </div>
                <div className={`mt-2`}>
                  <InputFull
                    type="text"
                    label="Last Name"
                    errCls={lastNameErr ? "border-red-400" : "border-gray-400"}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const isErr = validateInput("name", e.target.value);
                      setLastNameErr(isErr);
                    }}
                    onChange={(event) => {
                      setLastName(event.target.value);
                    }}
                  />
                  <div className={`p-1 text-xs text-red-600`}>
                    {lastNameErr}
                  </div>
                </div>
              </div>
              <br></br>
              <div>
                <div className="text-sm font-normal">Gender</div>
                <input
                  type="radio"
                  value="Male"
                  name="gender"
                  onChange={(event) => setGender(event.target.value)}
                  className="transform scale-x-150 scale-y-150 ml-4 mt-4 "
                />
                <label className="ml-3 text-sm ">Male</label>
                <input
                  type="radio"
                  value="Female"
                  name="gender"
                  onChange={(event) => setGender(event.target.value)}
                  className="transform scale-x-150 scale-y-150 ml-7 mt-4 "
                />
                <label className="ml-3 text-sm  ">Female</label>
              </div>
              <div className={`mt-4`}>
                <div className={`text-sm font-normal`}>Register as</div>
                <div className={`flex flex-auto`}>
                  <InputRadio
                    name="registerType"
                    value="Student"
                    onChange={(e) => {
                      setRegisterType(e.target.value);
                    }}
                  />
                  <InputRadio
                    name="registerType"
                    value="Trainer"
                    onChange={(e) => setRegisterType(e.target.value)}
                  />
                </div>
              </div>
              <div className={`mt-4`}>
                <InputFull
                  type="email"
                  label="Email"
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  errCls={emailErr ? "border-red-400" : "border-gray-400"}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const isErr = validateInput("email", e.target.value);
                    setEmailErr(isErr);
                  }}
                />
                <div className={`p-1 text-xs text-red-600`}>{emailErr}</div>
              </div>
              <div className={`mt-4 grid`}>
                <div className="z-0">
                  <InputFull
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                    errCls={passwordErr ? "border-red-400" : "border-gray-400"}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const isErr = validateInput("password", e.target.value);
                      setPasswordErr(isErr);
                    }}
                  />
                </div>
                <div className=" float-right z-40">
                  <FaEye
                    className="float-right -mt-7 mr-3  "
                    onClick={show_Password}
                  />
                </div>
                <div className={`p-1 text-xs text-red-600`}>{passwordErr}</div>
                <div className={`p-1 text-xs`}>{`Password must contain`}</div>
                <div className={`p-1 text-xs mt-6`}>
                  <ul>
                    <li>Atleast 8 characters</li>
                    <li>Atleast One special character</li>
                    <li>Atleast One upper case alphabet(A-Z)</li>
                    <li>Atleast One lower case alphabet(a-z)</li>
                    <li>Atleast One number (0-9)</li>
                  </ul>
                </div>
              </div>
              <div className={`mt-4 grid`}>
                <div className="z-0">
                  <InputFull
                    type={showConfirmPassword ? "text" : "password"}
                    label="Confirm Password"
                    onChange={(event) => {
                      setConfirmPassword(event.target.value);
                    }}
                    errCls={
                      cnfPasswordErr ? "border-red-400" : "border-gray-400"
                    }
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const isErr =
                        password === confirmPassword
                          ? ""
                          : "Confirm password mismatch";
                      setCnfPasswordErr(isErr);
                    }}
                  />
                </div>
                <div className=" float-right z-40">
                  <FaEye
                    className="float-right -mt-7 mr-3  "
                    onClick={show_Confirm_Password}
                  />
                </div>

                <div className={`p-1 text-xs text-red-600`}>
                  {cnfPasswordErr}
                </div>
              </div>
              <div className={`mt-6`}>
                <ThemeContext.Consumer>
                  {(context) => {
                    return (
                      <button
                        className={`w-full text-white bg-lp_blue text-xl p-2 rounded`}
                        onClick={() => {
                          signupHandler(context.getTheme);
                        }}
                      >
                        SIGN UP
                      </button>
                    );
                  }}
                </ThemeContext.Consumer>
              </div>
              <div
                className={`${marginTop} text-lp_blue text-xl  mb-16 font-bold text-center`}
              >
                <Link to="/signin">Already have an account? Login now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserInfo: (data: any) => {
      dispatch(UserInfoAction(data));
    },
  };
};

export default connect(null, mapDispatchToProps)(Signup);
