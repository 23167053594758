import axios, { AxiosError, AxiosResponse } from "axios";
import APIService from "../API";
import { ApplicationJsonConfigHeader, BaseUrl } from "../axios.config";
import { GeneralError } from "../Error.type";
import { TrainerMyCourseAPIResult } from "../MyCourses/MyCourseAPIService";
import {
  ConfirmPasswordEligibilityResult,
  ConfirmPasswordRequestData,
  ConfirmPasswordResult,
  LoginDataResult,
  LoginRequestData,
  RegisterDataResult,
  RegisterRequestData,
  ResetPasswordResult,
} from "./Auth.types";

const loginUrl: string = BaseUrl + "/authentication/login";
const registerUrl: string = BaseUrl + "/authentication/register";
const resetPasswordUrl: string = BaseUrl + "/authentication/resetPassword";
const resendMailUrl: string = BaseUrl + "/authentication/emailVerification";
const confirmPasswordUrl: string = BaseUrl + "/authentication/confirmPassword";
const confirmPasswordEligibilityUrl: string =
  BaseUrl + "/authentication/confirmPassword/eligibility";

class AuthService {
  login = async (
    loginData: LoginRequestData
  ): Promise<LoginDataResult | undefined> => {
    try {
      const response: AxiosResponse = await axios.post(loginUrl, loginData, {
        headers: {
          ...ApplicationJsonConfigHeader,
        },
        responseType: "json",
      });
      // console.log(response);

      if (response.status === 200) {
        return { data: response.data, statusCode: response.status };
      } else {
        const errorMsg = new GeneralError(response.data.error, response.status);
        return { errorMsg, statusCode: response.status };
      }
    } catch (e: any) {
      const err = e as AxiosError;
      const { error } = (err.response?.data as { error: string }) || {};
      // const error = new GeneralError(response.data.error, response.status)
      const errorMsg = new GeneralError(error || "Something went wrong", 400);
      return { errorMsg, statusCode: errorMsg.statusCode };
    }
  };

  register = async (
    registerData: RegisterRequestData
  ): Promise<RegisterDataResult> => {
    try {
      const response: AxiosResponse = await axios.post(
        registerUrl,
        registerData,
        {
          headers: ApplicationJsonConfigHeader,
          responseType: "json",
        }
      );
      if (response.status === 200 || response.status === 201) {
        return { data: response.data, statusCode: response.status };
      } else {
        const errorMsg = new GeneralError(response.data.error, response.status);
        return { errorMsg, statusCode: response.status };
      }
    } catch (e) {
      const err = e as AxiosError;
      const { error } = (err.response?.data as { error: string }) || {};
      const errorMsg = new GeneralError(error || "Something went wrong", 400);
      return { errorMsg, statusCode: errorMsg.statusCode };
    }
  };

  resendMail = async () => {
    const apiService = new APIService();

    return await apiService.postReqs({
      APIUrl: resendMailUrl,
      payload: {},
      resType: TrainerMyCourseAPIResult,
    });
  };

  resetPassword = async (email: string): Promise<ResetPasswordResult> => {
    const response: AxiosResponse = await axios.post(
      resetPasswordUrl,
      { email },
      {
        headers: ApplicationJsonConfigHeader,
        responseType: "json",
      }
    );
    if (response.status === 200 || response.status === 201) {
      return { data: response.data, statusCode: response.status };
    } else {
      const error = new GeneralError(response.data.error, response.status);
      return { error, statusCode: response.status };
    }
  };

  confirmPasswordReset = async (
    data: ConfirmPasswordRequestData
  ): Promise<ConfirmPasswordResult> => {
    const token = data.token;
    delete data['token'];
    try {
      const response: AxiosResponse = await axios.post(confirmPasswordUrl, data, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer "+ token
        },
        responseType: "json",
      });
      if (response.status === 200 || response.status === 201) {
        return { data: response.data, statusCode: response.status };
      } else {
        const error = new GeneralError(response.data.error, response.status);
        console.log("error",error)
        return { error, statusCode: response.status };
      }
    }
    catch (e) {
      const err = e as AxiosError;
      
      console.log(e);
      const { error } = err.response?.data as { error: string } || {};
      const generalError = new GeneralError(error || "Something went wrong", 400);
      return { error: generalError, statusCode: generalError.statusCode };
    }
  };

  confirmPasswordResetGet = async (token:String|undefined) => {
    try {
      const response: AxiosResponse = await axios.get(confirmPasswordUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer "+ token
        }
      });
      if (response.status === 200 || response.status === 201) {
        return { data: response.data, statusCode: response.status };
      } else {
        const error = new GeneralError(response.data.error, response.status);
        return { error, statusCode: response.status };
      }
    }

    catch (e) {
      const err = e as AxiosError;
      
      console.log(e);
      const { error } = err.response?.data as { error: string } || {};
      const generalError = new GeneralError(error || "Link Invalid. Please try to reset password again", 400);
      return { error: generalError, statusCode: generalError.statusCode };
    }
  };

  confirmPasswordEligibility = async (
    token: string
  ): Promise<ConfirmPasswordEligibilityResult> => {
    const response: AxiosResponse = await axios.post(
      confirmPasswordEligibilityUrl,
      { token },
      {
        headers: ApplicationJsonConfigHeader,
        responseType: "json",
      }
    );
    if (response.status === 200 || response.status === 201) {
      return { data: response.data, statusCode: response.status };
    } else {
      const error = new GeneralError(response.data.error, response.status);
      return { error, statusCode: response.status };
    }
  };
}

export default AuthService;
