import LogoContext from "../Context/LogoContext";
import React, { FC, useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { ApplicationJsonConfigHeader, BaseUrl } from "../Api/axios.config";
import { Assets } from "../Api/Assets";

const LogoProvider: FC = ({ children }) => {
  const [currLogo, setCurrLogo] = useState<string>("");
  useEffect(() => {
    fetchLogo();
  });

  const fetchLogo = async () => {
    const logo = await getLogo();
    setCurrLogo(logo);
  };

  return (
    <LogoContext.Provider
      value={{
        logo: currLogo,
        genderToggled: fetchLogo,
      }}
    >
      {children}
    </LogoContext.Provider>
  );
};

const getLogo = async (): Promise<string> => {
  try {
    const token: string | null = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + "/authentication/getLogo", {
      headers: {
        ...ApplicationJsonConfigHeader,
        authorization: `Bearer ${token}`,
      },
      responseType: "json",
    });
    return response.data.imageUrl;
  } catch (e) {
    const err = e as AxiosError;
    const { error } = (err.response?.data as { error: string }) || {};
    console.log(error);
    return Assets.FallBackLogoHerspringboard;
  }
};
export default LogoProvider;
