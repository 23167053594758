import axios, { AxiosError, AxiosResponse } from "axios";
import { ApplicationJsonConfigHeader, BaseUrl } from "../axios.config";
import { GeneralError, NetworkError, Payload } from "../Error.type";
import { CartDataType } from "./CartAPI.types";

const addToCartURL: string = `${BaseUrl}/cart/addCourse`;
const fetchCartCourseURL = `${BaseUrl}/cart/cartDetails`;
const removeCourseFromCartURL = `${BaseUrl}/cart/removeCourse`;

export type addCartDataRes = {
  data?: string;
  statusCode: number;
};

export type fetchCardDataRes = {
  cart?: [];
  totalAmount?: number;
};

export type removeCourseRes = {
  data?: string;
  statusCode: number;
};

export class CartAPIResult implements Payload<addCartDataRes, NetworkError> {
  statusCode: number;
  data?: addCartDataRes;
  errorMsg?: NetworkError;

  constructor(
    statusCode: number,
    data?: addCartDataRes,
    errorMsg?: NetworkError
  ) {
    this.statusCode = statusCode;
    this.data = data;
    this.errorMsg = errorMsg;
  }
}

export class FetchCartDataResult
  implements Payload<fetchCardDataRes, NetworkError>
{
  statusCode: number;
  data?: fetchCardDataRes;
  errorMsg?: NetworkError;

  constructor(
    statusCode: number,
    data?: fetchCardDataRes,
    errorMsg?: NetworkError
  ) {
    this.statusCode = statusCode;
    this.data = data;
    this.errorMsg = errorMsg;
  }
}

export class RemoveCourseFromCartResult
  implements Payload<removeCourseRes, NetworkError>
{
  statusCode: number;
  data?: removeCourseRes;
  errorMsg?: NetworkError;

  constructor(
    statusCode: number,
    data?: removeCourseRes,
    errorMsg?: NetworkError
  ) {
    this.statusCode = statusCode;
    this.data = data;
    this.errorMsg = errorMsg;
  }
}

export default class CartAPIService {
  addDataToCart = async (payload: CartDataType): Promise<CartAPIResult> => {
    // TODO: Remove token
    const token: string | null =
      localStorage.getItem("token");

    try {
      const response: AxiosResponse = await axios.post(addToCartURL, payload, {
        headers: {
          ...ApplicationJsonConfigHeader,
          authorization: `Bearer ${token}`,
        },
        responseType: "json",
      });
      if (response.status === 200) {
        return { data: response.data, statusCode: response.status };
      } else {
        const errorMsg = new GeneralError(response.data.error, response.status);
        return { errorMsg, statusCode: response.status };
      }
    } catch (e: any) {
      const err = e as AxiosError;
      const { error } = (err.response?.data as { error: string }) || {};
      const errorMsg = new GeneralError(error || "Something went wrong", 400);
      return { errorMsg, statusCode: errorMsg.statusCode };
    }
  };

  // Fetch course list
  fetchCourseInCartList = async (): Promise<FetchCartDataResult> => {
    // TODO: Remove token
    const token: string | null =
      localStorage.getItem("token");

    try {
      const response: AxiosResponse = await axios.get(fetchCartCourseURL, {
        headers: {
          ...ApplicationJsonConfigHeader,
          authorization: `Bearer ${token}`,
        },
        responseType: "json",
      });
      if (response.status === 200) {
        return { data: response.data, statusCode: response.status };
      } else {
        const errorMsg = new GeneralError(response.data.error, response.status);
        return { errorMsg, statusCode: response.status };
      }
    } catch (e: any) {
      const err = e as AxiosError;
      const { error } = (err.response?.data as { error: string }) || {};
      const errorMsg = new GeneralError(error || "Something went wrong", 400);
      return { errorMsg, statusCode: errorMsg.statusCode };
    }
  };

  // Remove course from cart
  removeCourseFromCart = async (
    courseId: string
  ): Promise<RemoveCourseFromCartResult> => {
    // TODO: Remove token
    const token: string | null =
      localStorage.getItem("token");

    try {
      const response: AxiosResponse = await axios.delete(
        removeCourseFromCartURL,
        {
          data: { courseId },
          headers: {
            ...ApplicationJsonConfigHeader,
            authorization: `Bearer ${token}`,
          },
          responseType: "json",
        }
      );
      if (response.status === 200) {
        return { data: response.data, statusCode: response.status };
      } else {
        const errorMsg = new GeneralError(response.data.error, response.status);
        return { errorMsg, statusCode: response.status };
      }
    } catch (e: any) {
      const err = e as AxiosError;
      const { error } = (err.response?.data as { error: string }) || {};
      const errorMsg = new GeneralError(error || "Something went wrong", 400);
      return { errorMsg, statusCode: errorMsg.statusCode };
    }
  };
}
